import React from "react"
import OurMission from "../images/our-mission.jpg"
import About2 from "../images/about2.jpg"
import About3 from "../images/about3.jpg"
import Layout from "../components/layout"
import Menu from "../components/global/Menu"
import Footer from "../components/global/Footer"
import CallToAction from "../components/global/CallToAction"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import SEO from "../components/seo"

const ProductPage = ({ data: { strapiAboutPage } }) => {
  return (
    <Layout bodyTheme="theme-about">
      <SEO
        title="About Razroo"
        keywords={[`about`, `razroo`, `angular`, `culture`]}
      />
      <header>
        <Menu />
      </header>
      <main className="page-wrapper">
        <section className="section section-first">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="about-content ml-0">
                  <div className="about-content-text">
                    <ReactMarkdown
                      source={strapiAboutPage["mission"] || "N/A"}
                    />
                  </div>
                </div>
                <div className="about-images">
                  <img alt="" src={OurMission} />
                  <img alt="" src={About2} />
                  <img alt="" src={About3} />
                </div>
                <div className="about-content">
                  <div className="about-content-text">
                    <ReactMarkdown
                      source={strapiAboutPage["whatsDrivesUs"] || "N/A"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section bg-contrast">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="ideals-card">
                  <div className="icon">
                    <i className="fad fa-hands-heart" />
                  </div>
                  <ReactMarkdown
                    source={strapiAboutPage["goodHeart"] || "N/A"}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="ideals-card">
                  <div className="icon">
                    <i className="fad fa-laptop-code" />
                  </div>
                  <ReactMarkdown source={strapiAboutPage["passion"] || "N/A"} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="about-content">
                  <div className="about-content-text">
                    <ReactMarkdown
                      source={strapiAboutPage["culture"] || "N/A"}
                      escapeHtml={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section pt-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <CallToAction />
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </main>
    </Layout>
  )
}

export const query = graphql`
  query AboutPageQuery {
    strapiAboutPage {
      mission
      passion
      whatsDrivesUs
      goodHeart
      culture
    }
  }
`

export default ProductPage
